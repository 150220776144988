import { gql } from '@apollo/client';

import { POSTAL_ADDRESS_FRAGMENT } from '../fragments';

export const POSTAL_ADDRESS_LOOKUP_QUERY = gql`
  query PostalAddressLookup($postalCode: String!, $streetNumber: String!) {
    postalAddressLookup(postalCode: $postalCode, streetNumber: $streetNumber) {
      ...PostalAddressFields
    }
  }

  ${POSTAL_ADDRESS_FRAGMENT}
`;
