import type { Spacings } from '@elseu/sdu-titan';
import { Block, DatePicker } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { formFieldErrorProps } from 'components/FormFields/formFieldErrorProps';
import { enGB, nl } from 'date-fns/locale';
import { dateTransform } from 'forms/helpers/dateTransform';
import { max } from 'lodash';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type DatePickerFieldProps = {
  label: string;
  isErrorHidden?: boolean;
  spaceAfter?: Spacings;
  isFutureDisabled?: boolean;
  [key: string]: any;
};

const DatePickerField = ({
  spaceAfter,
  name,
  maxDate,
  minDate,
  isFutureDisabled,
  isErrorHidden,
  ...props
}: DatePickerFieldProps) => {
  const { control } = useFormContext();
  const { i18n } = useLingui();
  const maxDateValue = useMemo(() => {
    if (maxDate) return dateTransform.input(maxDate) as Date;
    if (isFutureDisabled) {
      return dateTransform.input(new Date()) as Date;
    }
  }, [maxDate, isFutureDisabled]);

  const locale = i18n.locale === 'en-gb' ? enGB : nl;
  const minDateValue = useMemo(() => {
    return max([minDate, dateTransform.input(minDate) as Date]);
  }, [minDate]);
  return (
    <Block spaceAfter={spaceAfter}>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange, ...field }, formState: { errors } }) => {
          return (
            <DatePicker
              {...formFieldErrorProps(errors, name, isErrorHidden)}
              autoComplete="off"
              defaultSelectedDate={value}
              maxDate={maxDateValue}
              minDate={minDateValue}
              options={{
                locale,
                mode: 'single',
                labelButtonToday: t`Vandaag`,
              }}
              spellCheck="false"
              onChange={(date) => onChange(dateTransform.output(date))}
              {...field}
              {...props}
            />
          );
        }}
      />
    </Block>
  );
};

DatePickerField.defaultProps = {
  spaceAfter: 4,
};

export { DatePickerField };
