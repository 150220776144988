import type { PostalAddress } from '@elseu/sdu-evidend-graphql';
import { t } from '@lingui/macro';

const join = (arr: any[]): string => arr.filter(Boolean).join(' ');

export const formatAddressStreet = ({
  streetName,
  streetNumber,
  streetNumberAddition,
  postOfficeBoxNumber,
  countryCode,
  streetHouseNumber,
}: Pick<
  PostalAddress,
  | 'streetName'
  | 'streetNumber'
  | 'streetNumberAddition'
  | 'postOfficeBoxNumber'
  | 'countryCode'
  | 'streetHouseNumber'
>): string => {
  if (countryCode !== 'NL') return streetHouseNumber || '';
  const firstPart = postOfficeBoxNumber ? t`Postbus ${postOfficeBoxNumber}` : streetName;
  const number =
    streetNumber && streetNumberAddition ? `${streetNumber}-${streetNumberAddition}` : streetNumber;
  return join([firstPart, number]);
};

export const formatAddressPostalCity = ({
  postalCode,
  city,
  postalCodeCity,
  region,
  countryCode,
}: Pick<
  PostalAddress,
  'postalCode' | 'city' | 'postalCodeCity' | 'region' | 'countryCode'
>): string => {
  if (countryCode !== 'NL') return join([postalCodeCity, region]);
  return join([postalCode, city]);
};

export const formatAddressFull = (address: PostalAddress): string => {
  return join([formatAddressStreet(address), formatAddressPostalCity(address)]);
};
