import type { Spacings } from '@elseu/sdu-titan';
import { spacing } from '@elseu/sdu-titan';
import styled, { css } from 'styled-components';

interface IColumns {
  /* The number of columns. */
  count?: number;
  /* Alternative template. */
  template?: string;
  /* The spacing between columns. */
  gap?: Spacings;
  /* The spacing below the columns. */
  spaceAfter?: Spacings;
}

export const Columns = styled.div<IColumns>`
  display: grid;
  grid-template-columns: ${(props) => props.template || `repeat(${props.count}, minmax(0, 1fr))`};
  align-items: start;

  ${(props) =>
    props.gap &&
    css`
      column-gap: ${spacing(props.gap)};
    `}

  ${(props) =>
    props.spaceAfter &&
    css`
      margin-bottom: ${spacing(props.spaceAfter)};
    `}
`;

Columns.defaultProps = {
  count: 1,
  gap: 4,
};
