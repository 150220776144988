import { gql } from '@apollo/client';
import { NATURAL_PERSON_FRAGMENT, PARTICIPATION_FRAGMENT } from 'graphql/fragments';

export const NATURAL_PERSON_QUERY = gql`
  query NaturalPerson($naturalPersonId: ID!) {
    naturalPerson(naturalPersonId: $naturalPersonId) {
      ...NaturalPersonFields

      participation {
        ...ParticipationsFields
        register {
          legalEntity {
            id
            name
          }
        }
      }
      entitlement
    }
  }
  ${NATURAL_PERSON_FRAGMENT}
  ${PARTICIPATION_FRAGMENT}
`;

export const CREATE_NATURAL_PERSON = gql`
  mutation CreateNaturalPerson($registerId: ID!, $naturalPerson: NaturalPersonInput!) {
    createNaturalPerson(registerId: $registerId, naturalPerson: $naturalPerson) {
      ...NaturalPersonFields
    }
  }
  ${NATURAL_PERSON_FRAGMENT}
`;

export const UPDATE_NATURAL_PERSON = gql`
  mutation UpdateNaturalPerson($naturalPersonId: ID!, $naturalPerson: NaturalPersonInput!) {
    updateNaturalPerson(naturalPersonId: $naturalPersonId, naturalPerson: $naturalPerson) {
      ...NaturalPersonFields
    }
  }
  ${NATURAL_PERSON_FRAGMENT}
`;
