import { t } from '@lingui/macro';
import { InputField } from 'components/FormFields/InputField';

interface AddressForeignFormProps {
  nestedField?: string;
  required?: boolean;
}
export const AddressForeignForm = ({ nestedField, required }: AddressForeignFormProps) => {
  return (
    <>
      <InputField
        isLabelHidden
        label={t`Straat huisnummer`}
        name={`${nestedField}streetHouseNumber`}
        placeholder={t`Straat huisnummer`}
        required={required}
        spaceAfter={2}
      />

      <InputField
        isLabelHidden
        label={t`Postcode woonplaats`}
        name={`${nestedField}postalCodeCity`}
        placeholder={t`Postcode woonplaats`}
        required={required}
        spaceAfter={2}
      />

      <InputField
        isLabelHidden
        label={t`Regio`}
        name={`${nestedField}region`}
        placeholder={t`Regio`}
        spaceAfter={2}
      />
    </>
  );
};
