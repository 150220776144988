const integerTransform = {
  input: (value: any) => {
    if (value === null || value === undefined) return '';
    const input = parseInt(value);
    return isNaN(input) ? value : input.toString();
  },
  output: (value: string) => {
    if (value === '') return undefined;
    const output = parseInt(value);
    return isNaN(output) ? value : output;
  },
};

export { integerTransform };
