import type { Spacings } from '@elseu/sdu-titan';
import { Box, Divider, Flex, Heading, Text, useGreaterThan } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import { Columns } from 'components/Columns';
import type { ReactNode } from 'react';
import React from 'react';

export interface GeneralInfoContainerProps {
  contentArray: Array<{
    label: ReactNode;
    value: ReactNode;
  }>;
  header?: string;
  subHeader?: string;
  headerButton?: ReactNode;
  hasDivider?: boolean;
  spaceAfter?: Spacings;
}

export const GeneralInfoContainer = ({
  header,
  headerButton,
  subHeader,
  contentArray,
  hasDivider = true,
  spaceAfter = 10,
}: GeneralInfoContainerProps) => {
  const isLarge = useGreaterThan('s');
  return (
    <Box spaceAfter={spaceAfter}>
      {(header || subHeader) && (
        <Box spaceAfter={6}>
          {(header || headerButton) && (
            <Flex justifyContent="space-between">
              <Heading heading="h2">{header}</Heading>
              {headerButton && <Flex alignItems="center">{headerButton}</Flex>}
            </Flex>
          )}
          {subHeader && (
            <Text color="grey70" type="labelSmall">
              {subHeader}
            </Text>
          )}
        </Box>
      )}
      <Columns count={isLarge ? 2 : 1} spaceAfter={hasDivider ? 6 : 0}>
        {contentArray.map((content, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={index} spaceAfter={4}>
            <Box spaceAfter={2}>
              <Text color="grey80" type="labelBold">
                {content.label}
              </Text>
            </Box>
            <Box>
              <Text color="grey80" type="label">
                {content.value || t`Onbekend`}
              </Text>
            </Box>
          </Box>
        ))}
      </Columns>
      {hasDivider && <Divider />}
    </Box>
  );
};
