import { Label } from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import { Column, Columns } from 'components/Columns';
import { InputField } from 'components/FormFields/InputField';
import { generateInitials } from 'helpers/formatName';
import React, { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import * as yup from 'yup';

const getNameFormSchema = () => {
  return yup.object({
    firstName: yup.string().trim().nullable(true).required(),
    initials: yup.string().trim().nullable().required(),
    preposition: yup.string().trim().nullable(),
    lastName: yup.string().trim().nullable().required(),
    nickname: yup.string().trim().nullable(),
  });
};

export const nameFormSchema = getNameFormSchema();

export type NameFormValues = yup.InferType<typeof nameFormSchema>;

interface INameForm {
  nestedField?: string;
  hasColumns?: boolean;
}

const NameForm = ({ nestedField, hasColumns }: INameForm) => {
  const { control, setValue } = useFormContext();

  const [initials, firstName] = useWatch({
    control,
    name: [`${nestedField}initials`, `${nestedField}firstName`],
  });

  const onNameChange = useCallback(() => {
    if (firstName && !initials) {
      setValue(`${nestedField}initials`, generateInitials(firstName), {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      });
    }
  }, [firstName, initials, nestedField, setValue]);
  return (
    <Columns count={hasColumns ? 2 : 1}>
      <Column>
        <Label required spaceAfter={2}>
          <Trans>Volledige naam</Trans>
        </Label>
        <Columns count={2} gap={2} template="2fr 1fr">
          <Column>
            <InputField
              isLabelHidden
              required
              label={t`Voornamen`}
              name={`${nestedField}firstName`}
              placeholder={t`Voornamen`}
              spaceAfter={2}
              onBlur={onNameChange}
            />
          </Column>
          <Column>
            <InputField
              isLabelHidden
              required
              label={t`Initialen`}
              name={`${nestedField}initials`}
              placeholder={t`Initialen`}
              spaceAfter={2}
            />
          </Column>
        </Columns>

        <Columns count={2} gap={2} template="1fr 2fr">
          <Column>
            <InputField
              isLabelHidden
              label={t`Tussenvoegsel`}
              name={`${nestedField}preposition`}
              placeholder={t`Tussenv.`}
              spaceAfter={2}
            />
          </Column>
          <Column>
            <InputField
              isLabelHidden
              required
              label={t`Achternaam`}
              name={`${nestedField}lastName`}
              placeholder={t`Achternaam`}
              spaceAfter={2}
            />
          </Column>
        </Columns>

        <Column>
          <InputField
            isLabelHidden
            label={t`Roepnaam`}
            name={`${nestedField}nickname`}
            placeholder={t`Roepnaam`}
            spaceAfter={2}
          />
        </Column>
      </Column>
    </Columns>
  );
};

NameForm.defaultProps = {
  nestedField: '',
  hasColumns: false,
};

export { NameForm, nameFormSchema as NameFormSchema };
