import { Box, Label } from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import { Column, Columns } from 'components/Columns';
import { DatePickerField } from 'components/FormFields/DatePickerField';
import { InputField } from 'components/FormFields/InputField';
import { dateBasicValidation } from 'forms/helpers/dateTransform';
import { NameForm, nameFormSchema } from 'forms/NameForm';
import * as yup from 'yup';

const getNaturalPersonPersonalInfoFormSchema = () => {
  return yup.object({
    name: nameFormSchema,
    tag: yup.string().trim().nullable(),
    dateOfBirth: dateBasicValidation,
    dateOfDecease: dateBasicValidation,
    placeOfBirth: yup.string().trim().nullable(),
  });
};

export const naturalPersonPersonalInfoFormSchema = getNaturalPersonPersonalInfoFormSchema();

export type NaturalPersonPersonalInfoFormValues = yup.InferType<
  typeof naturalPersonPersonalInfoFormSchema
>;

interface NaturalPersonPersonalInfoFormProps {
  canViewPersonalData: boolean;
  nestedField?: string;
  hasColumns?: boolean;
}
const NaturalPersonPersonalInfoForm = ({
  canViewPersonalData,
  nestedField = '',
  hasColumns = true,
}: NaturalPersonPersonalInfoFormProps) => {
  return (
    <>
      <Columns count={hasColumns ? 2 : 1}>
        <Column>
          <Box spaceAfter={6}>
            <NameForm nestedField={`${nestedField}name.`} />
            {canViewPersonalData && (
              <Column>
                <InputField
                  isLabelHidden
                  label={t`Eigen kenmerk`}
                  name={`${nestedField}tag`}
                  placeholder={t`Eigen kenmerk`}
                  spaceAfter={2}
                />
              </Column>
            )}
          </Box>
        </Column>
        {canViewPersonalData && (
          <Column>
            <Box spaceAfter={6}>
              <Label spaceAfter={2}>
                <Trans>Geboortegegevens</Trans>
              </Label>
              <InputField
                isLabelHidden
                label={t`Geboorteplaats`}
                name={`${nestedField}placeOfBirth`}
                placeholder={t`Geboorteplaats`}
                spaceAfter={2}
              />
              <DatePickerField
                isFutureDisabled
                isLabelHidden
                label={t`Geboortedatum`}
                minDate={new Date(1850, 0, 1)}
                name={`${nestedField}dateOfBirth`}
                placeholder={t`Geboortedatum`}
                spaceAfter={2}
              />
            </Box>
            <DatePickerField
              isFutureDisabled
              label={t`Datum van overlijden`}
              minDate={new Date(1850, 0, 1)}
              name={`${nestedField}dateOfDecease`}
              placeholder={t`Datum van overlijden`}
              spaceAfter={2}
            />
          </Column>
        )}
      </Columns>
    </>
  );
};

export {
  NaturalPersonPersonalInfoForm,
  naturalPersonPersonalInfoFormSchema as NaturalPersonPersonalInfoFormSchema,
};
