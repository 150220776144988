import type { ShareBatch, ShareSeries, ShareType } from '@elseu/sdu-evidend-graphql';
import { groupBy } from 'lodash';

export const calculateSeriesTotal = (from: number | undefined, to: number | undefined): number => {
  if (!from || !to) return 0;
  if (from > to) return 0;
  return Math.abs(from - to) + 1;
};

export interface ShareBatchWithId extends ShareBatch {
  id: string;
}

// Get shareBatches for this shareType.
export const shareBatchesOfShareType = (shareType: ShareType, shareBatches: ShareBatchWithId[]) =>
  shareBatches.filter((shareBatch) => shareBatch.shareSeries.shareType.id === shareType.id);

export const shareBatchesByShareType = (shareBatches: ShareBatchWithId[]) =>
  Object.values(groupBy(shareBatches, 'shareSeries.shareType.id'));

export const shareBatchesByParty = (shareBatches: ShareBatchWithId[]) =>
  Object.values(groupBy(shareBatches, 'shareholder.id'));

export const shareSeriesMatchesShareBatch = (
  shareSeries: ShareSeries,
  shareBatch: ShareBatch,
): boolean => {
  return (
    shareSeries.shareType.id === shareBatch.shareSeries.shareType.id &&
    shareSeries.from >= shareBatch.shareSeries.from &&
    shareSeries.to <= shareBatch.shareSeries.to
  );
};

export const shareBatchForShareSeries = (shareSeries: ShareSeries, shareBatches: ShareBatch[]) =>
  shareBatches.find((shareBatch) => shareSeriesMatchesShareBatch(shareSeries, shareBatch));

export const generateIdFromShareBatch = ({ shareSeries }: Pick<ShareBatch, 'shareSeries'>) =>
  `${shareSeries.shareType.id}-${shareSeries.from}-${shareSeries.to}`;
