import type { SelectOptionProps } from '@elseu/sdu-titan';
import { Box, Label } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import { SelectField } from 'components/FormFields/SelectField';
import { countryCodeOptions } from 'forms/helpers/countryCodeOptions';
import { integerTransform } from 'forms/helpers/integerTransform';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import * as yup from 'yup';

import { AddressForeignForm } from './AddressForeignForm';
import type { AddressLocalFormProps } from './AddressLocalForm';
import { AddressLocalForm } from './AddressLocalForm';

const getConditionalRequiredSchema = (field: string, requiredCondition: (props: any) => boolean) =>
  yup
    .string()
    .nullable()
    .trim()
    .when(field, {
      is: (val: string) => requiredCondition(val),
      then: (schema) => schema.required(),
    });

export const getAddressFormSchema = ({ isRequired = true }) => {
  const field = 'countryCode';
  const isLocal = (cc: string) => isRequired && cc === 'NL';
  const isForeign = (cc: string) => isRequired && cc !== 'NL';
  return yup.object({
    streetName: getConditionalRequiredSchema(field, isLocal),
    streetNumber: getConditionalRequiredSchema(field, isLocal),
    streetNumberAddition: yup.string().nullable().trim(),
    postOfficeBoxNumber: yup.string().nullable().trim(),
    city: getConditionalRequiredSchema(field, isLocal),
    postalCode: getConditionalRequiredSchema(field, isLocal),
    countryCode: yup.string().nullable().trim().required(),

    streetHouseNumber: getConditionalRequiredSchema(field, isForeign),
    postalCodeCity: getConditionalRequiredSchema(field, isForeign),
    region: yup.string().nullable().trim(),
  });
};

export const addressFormSchema = getAddressFormSchema({});

export type AddressFormValues = yup.InferType<typeof addressFormSchema>;
export type OnSaveAdressFormFn = (formValues: AddressFormValues) => Promise<void>;

export interface AddressBaseFormProps extends AddressLocalFormProps {
  label: string;
  countryOptions?: SelectOptionProps[];
  hideCountryField?: boolean;
}
const AddressBaseForm = ({
  label,
  nestedField = '',
  countryOptions = countryCodeOptions(),
  hideCountryField = false,
  required,
  ...props
}: AddressBaseFormProps) => {
  const { control, setValue } = useFormContext();
  const countryCode = useWatch({
    control,
    name: `${nestedField}countryCode`,
  });

  useEffect(() => {
    if (!countryCode) return;
    const opts = {
      shouldValidate: true,
      shouldTouch: false,
      shouldDirty: false,
    };
    const resetFields =
      countryCode === 'NL'
        ? ['streetHouseNumber', 'postalCodeCity', 'region']
        : [
            'streetName',
            'streetNumber',
            'streetNumberAddition',
            'postOfficeBoxNumber',
            'city',
            'postalCode',
          ];
    resetFields.forEach((field) => setValue(`${nestedField}${field}`, null, opts));
  }, [countryCode, nestedField, setValue]);

  const isForeignAddress = countryCode && countryCode !== 'NL';
  return (
    <Box spaceAfter={6}>
      <Box>
        <Label required={required} spaceAfter={2}>
          {label}
        </Label>
      </Box>
      {!hideCountryField && (
        <SelectField
          isLabelHidden
          isSearchable
          isClearable={!required}
          label={t`Land`}
          name={`${nestedField}countryCode`}
          options={countryOptions}
          placeholder={t`Land`}
          required={required}
          spaceAfter={2}
          transform={integerTransform}
        />
      )}
      {!isForeignAddress && (
        <AddressLocalForm nestedField={nestedField} required={required} {...props} />
      )}
      {isForeignAddress && <AddressForeignForm nestedField={nestedField} required={required} />}
    </Box>
  );
};

export { AddressBaseForm, addressFormSchema as AddressFormSchema };
