import type { SelectOptionProps, SelectPropsMulti, SelectPropsSingle } from '@elseu/sdu-titan';
import { Block, Select } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import { formFieldErrorProps } from 'components/FormFields/formFieldErrorProps';
import type { FormValueTransform } from 'components/FormFields/InputField';
import { defaultTransform } from 'components/FormFields/InputField';
import { useEffect } from 'react';
import type {
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  UseFormStateReturn,
} from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';

const getDefaultTransform = (isMulti: boolean) =>
  isMulti
    ? { input: defaultTransform.input, output: (value: any) => value || [] }
    : defaultTransform;

type SelectFieldProps = {
  transform?: FormValueTransform;
  isErrorHidden?: boolean;
  testId?: string;
  [key: string]: any;
} & (SelectPropsSingle | SelectPropsMulti);

const defaultCreateOption = (inputValue: string): SelectOptionProps => ({
  label: t`"${inputValue}" toevoegen`,
  value: inputValue,
});

const SelectField = ({
  name,
  transform: customTransform,
  spaceAfter = 6,
  isErrorHidden = false,
  options,
  isCreateAllowed,
  defaultValue,
  isMulti,
  labelNoOptions = t`Geen opties`,
  createOption = defaultCreateOption,
  ...props
}: SelectFieldProps) => {
  const { control, setValue, watch } = useFormContext();
  const value = watch(name);
  const transform = customTransform || getDefaultTransform(isMulti as boolean);

  // If there is only one option or a defaultValue, automatically select it.
  useEffect(() => {
    if (isCreateAllowed) return;
    if (defaultValue && [undefined, null].includes(value)) {
      setValue(name, defaultValue, {
        shouldValidate: true,
        shouldDirty: false,
        shouldTouch: false,
      });
    }
    if (options.length === 1) {
      const firstValue = transform.output(options[0].value);
      setValue(name, firstValue, { shouldValidate: true, shouldDirty: false, shouldTouch: false });
    }
  }, [defaultValue, isCreateAllowed, name, options, setValue, transform, value]);

  const selectProps = ({
    field: { value, onChange, ...field },
    formState: { errors },
  }: {
    field: ControllerRenderProps<FieldValues, string>;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<FieldValues>;
  }) => ({
    ...formFieldErrorProps(errors, name, isErrorHidden),
    buttonWidth: '100%',
    createOption,
    defaultValue,
    isCreateAllowed,
    isMulti,
    labelNoOptions,
    options,
    value: transform.input(value),
    onChange: (value: any) => {
      onChange(transform.output(value));
    },
    ...props,
    ...field,
  });

  return (
    <Block spaceAfter={spaceAfter}>
      <Controller
        control={control}
        name={name}
        render={(props) => (
          <>
            {isMulti && <Select {...(selectProps(props) as SelectPropsMulti)} />}
            {!isMulti && <Select {...(selectProps(props) as SelectPropsSingle)} />}
          </>
        )}
      />
    </Block>
  );
};

export { SelectField };
