import { useLazyQuery } from '@apollo/client';
import { POSTAL_ADDRESS_LOOKUP_QUERY } from 'graphql/queries/postalAddress';
import type {
  PostalAddressLookup,
  PostalAddressLookupVariables,
} from 'types/graphql/PostalAddressLookup';

import type { AddressBaseFormProps } from './AddressBaseForm';
import { AddressBaseForm } from './AddressBaseForm';

const AddressForm = (args: AddressBaseFormProps) => {
  const [postalAddressLookup, { error, loading: isLoading }] = useLazyQuery<
    PostalAddressLookup,
    PostalAddressLookupVariables
  >(POSTAL_ADDRESS_LOOKUP_QUERY);

  return (
    <AddressBaseForm
      {...args}
      error={error}
      loading={isLoading}
      postalAddressLookup={(variables) => {
        return postalAddressLookup({
          variables,
        }).then(({ data }) => data?.postalAddressLookup);
      }}
    />
  );
};

export { AddressForm };
