import { Label } from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import { Column, Columns } from 'components/Columns';
import { InputField } from 'components/FormFields/InputField';
import React from 'react';
import * as yup from 'yup';

const getContactInfoFormSchema = () => {
  return yup.object({
    emailAddress: yup.string().email().trim().nullable(),
    phoneNumber: yup.string().trim().nullable(),
  });
};

export const contactInfoFormSchema = getContactInfoFormSchema();

export type ContactInfoFormValues = yup.InferType<typeof contactInfoFormSchema>;

interface IContactInfoForm {
  nestedField?: string;
  hasColumns?: boolean;
  groupLabels?: boolean;
}

const ContactInfoForm = ({ nestedField, hasColumns, groupLabels }: IContactInfoForm) => {
  return (
    <Columns count={hasColumns ? 2 : 1}>
      {groupLabels && (
        <Label spaceAfter={2}>
          <Trans>Contactgegevens</Trans>
        </Label>
      )}
      <Column>
        <InputField
          isLabelHidden={groupLabels}
          label={t`Telefoonnummer`}
          name={`${nestedField}phoneNumber`}
          placeholder={t`Telefoonnummer`}
          spaceAfter={groupLabels ? 2 : 6}
        />
      </Column>
      <Column>
        <InputField
          isLabelHidden={groupLabels}
          label={t`E-mailadres`}
          name={`${nestedField}emailAddress`}
          placeholder={t`E-mailadres`}
          spaceAfter={groupLabels ? 2 : 6}
        />
      </Column>
    </Columns>
  );
};

ContactInfoForm.defaultProps = {
  nestedField: '',
  hasColumns: true,
  groupLabels: false,
};

export { ContactInfoForm, contactInfoFormSchema as ContactInfoFormSchema };
