import type { Name, Notary, User } from '@elseu/sdu-evidend-graphql';
import { TooltipContainer } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import initials from 'initialism';
import { useRef, useState } from 'react';

export const formatNotaryName = (notary?: Notary | null): string => {
  if (!notary) return t`Onbekende notaris`;
  return `${notary.name.initials} ${notary.name.preposition ? `${notary.name.preposition} ` : ''}${
    notary.name.lastName
  }`;
};

export const formatNaturalPersonFirstName = (name: Name): string => {
  if (name.initials) {
    return `${name.initials} (${name.nickname || name.firstName})`;
  }
  return `${name.firstName}${name.nickname ? ` (${name.nickname})` : ''}`;
};

export const formatNaturalPersonLastName = (name: Name): string => {
  return `${name.preposition ? `${name.preposition} ` : ''}${name.lastName}`;
};

export const formatNaturalPersonName = (name: Name, isDeceased = false): string => {
  const nameString = `${formatNaturalPersonFirstName(name)} ${formatNaturalPersonLastName(
    name,
  )}`.trim();
  if (isDeceased) return t`Erven van ${nameString}`;
  return nameString;
};

export const generateInitials = (nameFirst: string): string => {
  if (!nameFirst) return '';
  return initials(nameFirst).replace(/([A-Z])/g, '$1.');
};

export const FormatUserName = (user: Pick<User, 'name' | 'email'>) => {
  const referenceElement = useRef<HTMLDivElement | null>(null);
  const [isShown, setShown] = useState(false);
  const { name, email } = user;
  const formattedName = formatNaturalPersonName(name);
  return (
    <>
      <span
        ref={referenceElement}
        onMouseEnter={() => setShown(true)}
        onMouseLeave={() => setShown(false)}
      >
        {formattedName}
      </span>
      <TooltipContainer
        content={email}
        isShown={isShown}
        referenceElement={referenceElement.current}
        title={formattedName}
        onClose={() => setShown(false)}
      />
    </>
  );
};
