import styled from 'styled-components';

interface IColumn {
  /* The grid columns used by this column. See https://developer.mozilla.org/en-US/docs/Web/CSS/grid-column */
  position?: string;
}

export const Column = styled.div<IColumn>`
  grid-column: ${(props) => props.position};

  /* https://css-tricks.com/preventing-a-grid-blowout/ */
  min-width: 0;
`;

Column.defaultProps = {
  position: 'auto',
};
