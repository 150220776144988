import type { ApolloError } from '@apollo/client';
import { Box, Card, CardHeader, Flex, Link, Loader, Text } from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import { Column, Columns } from 'components/Columns';
import { InputField } from 'components/FormFields/InputField';
import { formatAddressPostalCity, formatAddressStreet } from 'helpers/address';
import { get } from 'lodash';
import { useState } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import type {
  PostalAddressLookup_postalAddressLookup,
  PostalAddressLookupVariables,
} from 'types/graphql/PostalAddressLookup';

const FlexHeader = styled(Flex)`
  width: 100%;
`;

export interface AddressLocalFormProps {
  nestedField?: string;
  cardBackgroundVariant?: 'grey' | 'white';
  isManualEntry?: boolean;
  required?: boolean;
  postalAddressLookup?: (
    postalAddressLookup: PostalAddressLookupVariables,
  ) => Promise<PostalAddressLookup_postalAddressLookup | undefined>;
  loading?: boolean;
  error?: ApolloError;
}
export const AddressLocalForm = ({
  nestedField = '',
  cardBackgroundVariant,
  isManualEntry: isManualEntryDefault,
  postalAddressLookup,
  loading,
  error,
  required,
}: AddressLocalFormProps) => {
  const [isManualEntry, setManualEntry] = useState(isManualEntryDefault);
  const { control, setValue, watch } = useFormContext();
  const [postalCode, streetNumber, streetName] = useWatch({
    control,
    name: [`${nestedField}postalCode`, `${nestedField}streetNumber`, `${nestedField}streetName`],
  });

  const address = watch(nestedField || (null as any));
  const { errors } = useFormState({ control });

  const isPostalCodeValid = !get(errors, `${nestedField}postalCode`) && postalCode;
  const isStreetNumberValid = !get(errors, `${nestedField}streetNumber`) && streetNumber;

  const resetStreetAndCity = (
    opts = {
      shouldValidate: false,
      shouldTouch: true,
      shouldDirty: true,
    },
  ) => {
    setValue(`${nestedField}city`, null, opts);
    setValue(`${nestedField}streetName`, null, opts);
  };

  const onBlur = () => {
    if (isManualEntry || !postalAddressLookup || !isPostalCodeValid || !isStreetNumberValid) return;
    postalAddressLookup({
      postalCode,
      streetNumber,
    })
      .then((postalAddressLookupResponse) => {
        if (!postalAddressLookupResponse) return resetStreetAndCity();
        const { city, streetName } = postalAddressLookupResponse;
        const opts = {
          shouldValidate: true,
          shouldTouch: true,
          shouldDirty: true,
        };
        setValue(`${nestedField}city`, city, opts);
        setValue(`${nestedField}streetName`, streetName, opts);
        setValue(`${nestedField}countryCode`, 'NL', opts);
      })
      .catch(resetStreetAndCity);
  };

  const hasAddress = !isManualEntry && isPostalCodeValid && isStreetNumberValid && streetName;
  return (
    <>
      <InputField
        isLabelHidden
        isSuccess={!isManualEntry && isPostalCodeValid}
        label={t`Postcode`}
        name={`${nestedField}postalCode`}
        placeholder={t`Postcode`}
        required={required}
        spaceAfter={2}
        onBlur={onBlur}
      />
      <Columns count={2} gap={4}>
        <Column>
          <InputField
            isLabelHidden
            isSuccess={!isManualEntry && isStreetNumberValid}
            label={t`Nr.`}
            name={`${nestedField}streetNumber`}
            placeholder={t`Nr.`}
            required={required}
            spaceAfter={2}
            onBlur={onBlur}
          />
        </Column>
        <Column>
          <InputField
            isLabelHidden
            label={t`Toev.`}
            name={`${nestedField}streetNumberAddition`}
            placeholder={t`Toev.`}
            spaceAfter={2}
          />
        </Column>
      </Columns>

      {!isManualEntry && (
        <Box>
          {!loading && !hasAddress && !error && (
            <Link onClick={() => setManualEntry(true)}>
              <Trans>Handmatig invoeren</Trans>
            </Link>
          )}
          {loading && (
            <Box py={8}>
              <Loader height={48} variant="spinner" />
            </Box>
          )}
          {hasAddress && address && (
            <Card backgroundVariant={cardBackgroundVariant} showDivider={false}>
              <CardHeader>
                <FlexHeader flexWrap="wrap-reverse" justifyContent="space-between">
                  <Text color="grey80" spaceAfter={2} type="labelLargeBold">
                    <Trans>Adres</Trans>
                  </Text>
                  <Link onClick={() => setManualEntry(true)}>
                    <Trans>Wijzig</Trans>
                  </Link>
                </FlexHeader>
              </CardHeader>
              <Text isBlock color="grey80" type="paragraph">
                {formatAddressStreet(address)}
              </Text>
              <Text color="grey80" type="paragraph">
                {formatAddressPostalCity(address)}
              </Text>
            </Card>
          )}
          {error && (
            <Card backgroundVariant={cardBackgroundVariant} showDivider={false}>
              <CardHeader>
                <FlexHeader flexWrap="wrap-reverse" justifyContent="space-between">
                  <Text color="grey80" spaceAfter={2} type="labelLargeBold">
                    <Trans>Adres niet gevonden</Trans>
                  </Text>
                  <Link spaceAfter={2} onClick={() => setManualEntry(true)}>
                    <Trans>Handmatig invoeren</Trans>
                  </Link>
                </FlexHeader>
              </CardHeader>
              <Text color="grey80" type="paragraph">
                <Trans>Mogelijk heb je een typfout gemaakt of is het adres nog niet bekend.</Trans>
              </Text>
            </Card>
          )}
        </Box>
      )}

      {isManualEntry && (
        <>
          <InputField
            isLabelHidden
            label={t`Straatnaam`}
            name={`${nestedField}streetName`}
            placeholder={t`Straatnaam`}
            required={required}
            spaceAfter={2}
          />

          <InputField
            isLabelHidden
            label={t`Woonplaats`}
            name={`${nestedField}city`}
            placeholder={t`Woonplaats`}
            required={required}
            spaceAfter={2}
          />
        </>
      )}
    </>
  );
};
